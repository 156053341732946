interface RouteNameMapping {
  [key: string]: string;
}

export const routeNameMapping: RouteNameMapping = {
  '/login': 'Login',
  '/signup': 'Signup',
  '/': 'Dashboard',
  '/profile': 'Profile',
};

export const getRouteScreenName = () =>
  process.browser
    ? document.title.replace(' - CryptoTendies Notification PWA', '')
    : '';
