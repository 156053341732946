import { logEvent } from 'firebase/analytics';
import type { Analytics } from 'firebase/analytics';
import { getRouteScreenName } from './routing';

const isProduction = process.env.NEXT_PUBLIC_ENVIRONMENT === 'production';
const hasBroadcastChannelSupport =
  process.browser && typeof BroadcastChannel !== 'undefined';
const broadcastChannelNotificationRecievedKey =
  'sw-background-notification-received';

export const analyticsSettings = {
  config: {
    screen_name: getRouteScreenName(),
    ...(isProduction
      ? {}
      : {
          debug_mode: true,
        }),
  },
};

export const initiateBackgroundNotificationTracker = async (
  analytics: Analytics,
): Promise<void> => {
  try {
    let channel;

    if (!hasBroadcastChannelSupport) {
      const { BroadcastChannel } = await import('broadcast-channel');
      channel = new BroadcastChannel(broadcastChannelNotificationRecievedKey);
    } else {
      channel = new BroadcastChannel(broadcastChannelNotificationRecievedKey);
    }

    channel.addEventListener('message', async event => {
      const { data, notification } = event?.data || {};

      if (data && notification) {
        const { getNotificationMetadata } = await import('./notification');

        const { messageId, timestamp, deviceTimestamp } =
          getNotificationMetadata(event.data) || {};

        logEvent(analytics, 'notification_background', {
          message_id: messageId,
          message_time: timestamp,
          message_device_time: deviceTimestamp,
          message_name: notification.title,
        });
      } else {
        const { logError } = await import('./logger');

        const error = new Error(
          'Missing required background notification parameters',
        );

        logError(error, event?.data || {});
      }
    });
  } catch (error) {
    // Log the error, it is likely the browser does not support BroadcastChannel API.
    const { logError } = await import('./logger');

    logError(error);
  }
};
