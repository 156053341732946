import React, { useState, useEffect } from 'react';
import type { FC } from 'react';
import dynamic from 'next/dynamic';
import Router, { useRouter } from 'next/router';
import type { AppProps } from 'next/app';
import NProgress from 'nprogress';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { AuthProvider } from '../contexts/AuthContext';
import { routeNameMapping } from '../utilities/routing';
import 'nprogress/nprogress.css';
import '../styles/globals.css';

// Dynamic imports
const Head = dynamic(() => import('next/head'));
const ServiceWorkerUpdateNotification = dynamic(
  () => import('../components/ServiceWorkerUpdateNotification'),
);

let previousRouteUrl = '';

// Bind route events for nprogress
Router.events.on('routeChangeStart', (url: string) =>
  url !== window.location.pathname ? NProgress.start() : null,
);
Router.events.on('routeChangeComplete', (url: string) => {
  NProgress.done();

  if (process.browser && url !== previousRouteUrl) {
    (async () => {
      const [
        { setCurrentScreen },
        { firebaseAnalytics },
        { getRouteScreenName },
      ] = await Promise.all([
        import('firebase/analytics'),
        import('../utilities/firebase'),
        import('../utilities/routing'),
      ]);

      // Page views are captured by GA4 Enhanced Measurement
      // See https://developers.google.com/analytics/devguides/collection/ga4/page-view#default-pageviews
      // See https://developers.google.com/analytics/devguides/collection/ga4/tag-guide#page_views_and_screen_views
      // See https://developers.google.com/analytics/devguides/collection/ga4/screen-view
      if (firebaseAnalytics) {
        setCurrentScreen(firebaseAnalytics, getRouteScreenName());
      }
    })();

    previousRouteUrl = url;
  }
});
Router.events.on('routeChangeError', () => NProgress.done());

const theme = createTheme({
  typography: {
    fontFamily:
      '"Courier New", Courier, "Lucida Sans Typewriter", "Lucida Typewriter", monospace',
  },
});

const TendiesNotificationApp: FC<AppProps> = ({
  Component,
  pageProps,
}: AppProps) => {
  const router = useRouter();
  const routeName = routeNameMapping[router.pathname.toLowerCase()] || '';
  const [isUpdateReady, setIsUpdateReady] = useState(false);

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      window.workbox.addEventListener('waiting', event => {
        if (!event?.wasWaitingBeforeRegister) {
          setIsUpdateReady(true);
        }
      });
    }
  });

  return (
    <>
      <Head>
        {/* This is required to provide a server rendered title to auth protected routes (guest and auth) */}
        <title>{routeName} - CryptoTendies Notification PWA</title>
      </Head>
      <AuthProvider>
        <ThemeProvider theme={theme}>
          <Component {...pageProps} />
        </ThemeProvider>
      </AuthProvider>
      {isUpdateReady && <ServiceWorkerUpdateNotification />}
    </>
  );
};

export default TendiesNotificationApp;
