import React, { useContext, useState, useEffect } from 'react';
import type { FC, ReactElement } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import type { User as FirebaseUser, Unsubscribe } from 'firebase/auth';
import { setUserId } from 'firebase/analytics';
import { firebaseAuth, firebaseAnalytics } from '../utilities/firebase';

interface IAuthProviderProps {
  children: ReactElement;
}

interface IAuthContextValue {
  user: FirebaseUser | undefined;
  signup(email: string, password: string): Promise<void>;
  login(email: string, password: string): Promise<void>;
  logout(): Promise<void>;
}

const AuthContext = React.createContext<IAuthContextValue | undefined>(
  undefined,
);

export const useAuth = (): IAuthContextValue | undefined =>
  useContext<IAuthContextValue | undefined>(AuthContext);

export const AuthProvider: FC<IAuthProviderProps> = ({
  children,
}: IAuthProviderProps): ReactElement => {
  const [user, setUser] = useState<FirebaseUser | undefined>(undefined);
  const [isReady, setIsReady] = useState(false);

  const errorHandler = async (error: Error) => {
    const { logError } = await import('../utilities/logger');

    logError(error);
  };

  const signup = async (email: string, password: string) => {
    if (firebaseAuth) {
      const { createUserWithEmailAndPassword } = await import('firebase/auth');

      await createUserWithEmailAndPassword(firebaseAuth, email, password);
    } else {
      const error = new Error(
        'The `firebaseAuth` instance is missing in the AuthContext `signup` call.',
      );

      errorHandler(error);
    }
  };

  const login = async (email: string, password: string) => {
    if (firebaseAuth) {
      const { signInWithEmailAndPassword } = await import('firebase/auth');

      await signInWithEmailAndPassword(firebaseAuth, email, password);
    } else {
      const error = new Error(
        'The `firebaseAuth` instance is missing in the AuthContext `login` call.',
      );

      errorHandler(error);
    }
  };

  const logout = async () => {
    if (firebaseAuth) {
      const [{ signOut }, { unsetLoggerUser }, { deleteMessagingToken }] =
        await Promise.all([
          import('firebase/auth'),
          import('../utilities/logger'),
          import('../utilities/firebase/messaging'),
        ]);

      if (user) {
        await deleteMessagingToken(user);
      }

      await signOut(firebaseAuth);

      unsetLoggerUser();
    } else {
      const error = new Error(
        'The `firebaseAuth` instance is missing in the AuthContext `logout` call.',
      );

      errorHandler(error);
    }
  };

  useEffect((): Unsubscribe | undefined => {
    const unsubscribeSetUser = onAuthStateChanged(
      firebaseAuth,
      async firebaseUser => {
        setUser(firebaseUser || undefined);

        setIsReady(true);

        if (firebaseAnalytics && firebaseUser?.uid) {
          setUserId(firebaseAnalytics, firebaseUser.uid);
        }

        const { setLoggerUser } = await import('../utilities/logger');

        setLoggerUser(firebaseUser);
      },
    );

    return unsubscribeSetUser;
  }, []);

  const value = {
    user,
    signup,
    login,
    logout,
  };

  return (
    <AuthContext.Provider value={value}>
      {isReady && children}
    </AuthContext.Provider>
  );
};
