import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { initializeAnalytics } from 'firebase/analytics';
import {
  analyticsSettings,
  initiateBackgroundNotificationTracker,
} from './analytics';

let app = null;

// Configure Firebase.
if (!app) {
  try {
    app = initializeApp({
      apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
      authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
      projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
      storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
      messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
      appId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_APP_ID,
      measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID,
    });
  } catch (error) {
    // Throw away duplicate application errors (temp fix)
    if (error.code !== 'app/duplicate-app') {
      throw error;
    }
  }
}

const analytics =
  process.browser && app
    ? initializeAnalytics(app, analyticsSettings)
    : undefined;

if (analytics) {
  initiateBackgroundNotificationTracker(analytics);
}

export const firebaseAnalytics = analytics;
export const firebaseAuth = getAuth(app || undefined);
export const firebaseApp = app;
